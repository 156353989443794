import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import WrappedCookieNotice from '../components/CookieNotice';

function deleteCookie(name: string) {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

function isCookieSet(cookieName: string) {
	let cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i].trim();
		if (cookie.startsWith(cookieName + '=')) {
			return true;
		}
	}
	return false;
}

const CookieConsentNoticePage = () => {
	useEffect(() => {
		if (isCookieSet('necessary')) {
			deleteCookie('necessary');
			// reload page
			window.location.reload();
		} else {
			const interval = setInterval(() => {
				if (isCookieSet('necessary')) {
					window.location.href = '../';
				}
			}, 500);
			return () => {
				clearInterval(interval);
			};
		}
	}, []);
	return <WrappedCookieNotice />;
};

export default CookieConsentNoticePage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
